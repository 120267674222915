// <div data-controller="components--auth">
//
// </div>
import {Controller} from "stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
    static targets = ["form", "email", "pin", "marketingAgreement", "confirmButtons", "authenticationField", "privacyAgreement"];
    static classes = [""]
    static values = {};

    initialize() {
        this.pinSent = false;
    }

    startAuthentication(e) {
        if (!this.emailTarget.checkValidity()) {
            this.emailTarget.focus();
            return false;
        }

        if (this.hasPrivacyAgreementTarget) {
            if (!this.privacyAgreementTarget.checkValidity()) {
                this.privacyAgreementTarget.focus();
                return false;
            }
        }

        this.emailTarget.readOnly = true;
        api.post("/api/users/send_pin", {email: this.emailTarget.value}).then((res) => {
            if (res.status === 200) {
                this.pinSent = true;
                this.authenticationFieldTarget.classList.remove("hidden");
                this.pinTarget.focus();
            } else {
                alert(res.data.msg);
            }
        })
    }

    resendPin(e) {
        e.target.classList.remove("text-green-700");
        e.target.classList.add("text-yellow-500");
        e.target.innerText = "발송중..";
        api.post("/api/users/send_pin", {email: this.emailTarget.value}).then((res) => {
            if (res.status === 200) {
                this.pinSent = true;
                e.target.classList.remove("text-yellow-500", "cursor-pointer");
                e.target.classList.add("text-green-700");
                e.target.innerText = "재발송완료 (스팸함도 확인해주세요)";
                e.target.dataset.action = null;
                this.authenticationFieldTarget.classList.remove("hidden");
            } else {
                e.target.classList.remove("text-yellow-700");
                e.target.classList.add("text-red-700");
                e.target.innerText = res.data.msg || "발송실패 (재시도 하기)";
                this.confirmButtonsTarget.style.removeProperty("display");
            }
        })
    }

    connect() {
        this.formTarget.addEventListener("keydown", (e) => {
            const keyCode = e.keyCode || e.which;

            if (keyCode === 13 && !this.pinSent) {
                e.preventDefault();
                this.startAuthentication();
                return false;
            }
        })
    }

    disconnect() {

    }
}