// <div data-controller="users--sessions">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [
    "toEmailPassword", "toEmailLink",
    "emailPasswordForm", "emailLinkForm", "emailLinkFormMail", "emailPasswordFormMail",
    "sendMagicLinkButton", "notification", "notFound", "notFoundMsg", "redirectPath",
    "linkSignInForm"
  ];

  setEmailLinkForm(e) {
    this.emailLinkFormMailTarget.value = this.emailPasswordFormMailTarget.value;
    this.emailPasswordFormTarget.classList.add("hidden");
    this.emailLinkFormTarget.classList.remove("hidden");
    this.toEmailLinkTarget.classList.add("hidden");
    this.toEmailPasswordTarget.classList.remove("hidden");
  }

  setEmailPasswordForm(e) {
    this.emailPasswordFormMailTarget.value = this.emailLinkFormMailTarget.value;
    this.emailPasswordFormTarget.classList.remove("hidden");
    this.emailLinkFormTarget.classList.add("hidden");
    this.toEmailLinkTarget.classList.remove("hidden");
    this.toEmailPasswordTarget.classList.add("hidden");
  }

  sendMagicLink() {
    if (!this.emailLinkFormMailTarget.checkValidity()) {
      this.emailLinkFormMailTarget.focus();
      return false;
    }
    this.linkSignInFormTarget.submit();
  }

  connect() {
    this.emailLinkFormMailTarget.addEventListener("keypress", (e) => {
      const keyCode = e.keyCode || e.which;

      if (keyCode === 13) {
        this.sendMagicLink();
      }
    })
  }
}