import { Controller } from "stimulus"

export default class extends Controller {
  //
  connect() {
    this.editorElem = this.context.element.children[0];
    this.viewElem = this.context.element.children[1];
    this.editorScrollTop = this.editorElem.scrollTop;
  }

  onScroll(e) {
    const diff = this.editorElem.scrollTop - this.editorScrollTop;
    this.viewElem.scrollTop = this.viewElem.scrollTop + diff;
    this.editorScrollTop = this.editorElem.scrollTop;
  }
}


// const calculateScrollPercentage = (target, childElemHeight) => {
//   try {
//     if (!target) throw 'scrolling element height not found'
//
//     let bool = target === document
//
//     let scrollHeight = (() => {
//       if (bool) return childElemHeight
//       return target.scrollHeight
//     })()
//
//     if (!scrollHeight) throw 'child element height not found'
//
//     let clientHeight = bool ? window.innerHeight : target.clientHeight
//     let scrollTop = bool ? window.scrollY : target.scrollTop
//
//     let gottaScroll = scrollHeight - clientHeight
//     return Math.round((scrollTop / gottaScroll) * 100000) / 1000
//   } catch (err) {
//     console.error(err)
//   }
// }