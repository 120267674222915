import { readAndCompressImage } from 'browser-image-resizer';

export function milisecondToHours(milisecond) {
  return (milisecond / (1000 * 60 * 60));
}

export function appendScript(url, callback) {
  const head = document.getElementsByTagName('head')[0];
  const theScript = document.createElement('script');
  theScript.type = 'text/javascript';
  theScript.src = url;
  theScript.onreadystatechange = callback;
  theScript.onload = callback;
  head.appendChild(theScript);
}

export function readImage(url) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject();
    img.src = url;
  });
}

export function numberWithDelimiter(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function krw(price) {
  if (price === 0) {
    return "무료";
  }

  return `${numberWithDelimiter(price)} 원`;
}

export default {};