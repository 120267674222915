// <div data-controller="components--modal">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [ "modal" ];

  showModal() {
    this.modalTarget.style.removeProperty("display");
  }

  hideModal() {
    this.modalTarget.style.display = "none";
  }

  connect() {
    if (this.data.get("initial-status") === "true") {
      this.showModal();
    }
  }
}