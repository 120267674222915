// <div data-controller="pay--checkout">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";
import * as helpers from "@/utils/helpers";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [
      "form",
      "product",
      "productNameLabel",
      "productPriceLabel",
      "pg"
  ];
  static classes = [ "" ]
  static values = {
    impUid: String,
    customerUid: String,
    customerEmail: String,
    customerName: String,
    submitUrl: String,
    productData: Object
  };

  initialize() {

  }

  get creditsChargeModalController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("creditsChargeModal"), "components--modal")
  }

  get product() {
    if (this.productTarget.value) {
      return this.productDataValue[this.productTarget.value];
    }
  }

  showModal() {
    if (this.product) {
      this.productNameLabelTarget.innerText = this.product.name;
      this.productPriceLabelTarget.innerText = helpers.krw(this.product.price);

      if (this.creditsChargeModalController) {
        this.creditsChargeModalController.showModal();
      }
    } else {
      this.productTarget.reportValidity();
    }
  }

  loadScript = (callback) => {
    const impId = this.impUidValue;

    if (window.IMP) {
      IMP.init(impId);
      callback(IMP);
    } else {
      appendScript("https://code.jquery.com/jquery-1.12.4.min.js", () => {
        appendScript("https://cdn.iamport.kr/js/iamport.payment-1.2.0.js", () => {
          IMP.init(impId);
          callback(IMP);
        });
      });
    }
  }

  get currentPg() {
    let checkedPg;
    this.pgTargets.forEach((target) => {
      if (target.checked) {
        checkedPg = target.value;
      }
    });
    return checkedPg;
  }

  startPaymentRequest() {
    if (this.formTarget.checkValidity()) {
      this.loadScript((iamport) => {
        const params = {
          pg: this.currentPg,
          pay_method: 'card',
          merchant_uid: 'maily_so_' + new Date().getTime(),
          name: this.product.name,
          amount: this.product.price,
          buyer_email: this.customerEmailValue,
          buyer_name: this.customerNameValue,
          m_redirect_url: this.submitUrlValue
        }

        iamport.request_pay(params, this.submitResponse);
      });
    } else {
      this.formTarget.reportValidity();
    }
  }

  submitResponse = (response) => {
    console.log(response);
    if (response.success) {
      const imp_uid = response.imp_uid;

      api.post(this.submitUrlValue, {
        product: this.productTarget.value,
        imp_uid: imp_uid
      }).then((res) => {
        if (res.data.redirect_url) {
          window.location.href = res.data.redirect_url;
        } else {
          window.location.reload();
        }
      });
    }
  }



  connect() {
  }

  disconnect() {

  }
}