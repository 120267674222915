// <div data-controller="components--form-validator">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [ "emailField", "notification", "pinContainer", "userPinField", "pinNotification", "pinButton", "verifyButton" ];

  initialize() {
    this.emailValidnessTimeout = null;
  }

  sendPin(e) {
    e.target.classList.remove("text-green-700");
    e.target.classList.add("text-yellow-500");
    e.target.innerText = "발송중..";
    api.post("/api/users/send_pin", { email: this.emailFieldTarget.value }).then((res) => {
      if (res.status === 200) {
        this.pinSent = true;
        e.target.classList.remove("text-yellow-500", "cursor-pointer");
        e.target.classList.add("text-green-700");
        e.target.innerText = "발송완료 (스팸함도 확인해주세요)";
      } else {
        e.target.classList.remove("text-yellow-700");
        e.target.classList.add("text-red-700");
        e.target.innerText = res.data.msg || "발송실패 (재시도 하기)";
      }
    })
  }

  verifyPin(e) {
    const errorClass = "bg-white dark:bg-stone-900 bg-dark-maily text-slate-900 dark:text-slate-200 shadow-sm focus:ring-yellow-300 focus:border-yellow-300 block w-full sm:text-sm border-yellow-300 rounded-none rounded-l-md"
    const succeedClass = "bg-white dark:bg-stone-900 bg-dark-maily text-slate-900 dark:text-slate-200 shadow-sm focus:ring-green-333 focus:border-green-333 block w-full sm:text-sm border-green-333 rounded-none rounded-l-md"
    if (this.userPinFieldTarget.value.trim() === "") {
      this.pinNotificationTarget.className = "mt-2 text-sm text-red-700";
      this.pinNotificationTarget.innerText = "핀 번호를 입력해주세요";

      return;
    }

    api.post("/api/users/verify_pin", { email: this.emailFieldTarget.value, user_pin: this.userPinFieldTarget.value }).then((res) => {
      if (res.status === 200) {
        if (res.data.verified) {
          this.pinNotificationTarget.className = "mt-2 text-sm text-green-700 dark:text-green-700";
          this.pinNotificationTarget.innerText = res.data.msg;
          this.emailFieldTarget.className = succeedClass;
          this.emailFieldTarget.readOnly = true;
          this.emailFieldTarget.dataset.action = null;
          this.userPinFieldTarget.className = succeedClass;
          this.userPinFieldTarget.readOnly = true;
          this.notificationTarget.className = "hidden";
          this.pinButtonTarget.className = "hidden";
          this.verifyButtonTarget.className = "hidden"
        } else {
          this.pinNotificationTarget.className = "mt-2 text-sm text-red-700";
          this.pinNotificationTarget.innerText = res.data.msg;
          this.userPinFieldTarget.className = errorClass
          if (res.data.is_reset) {
            this.pinButtonTarget.classList.add("text-green-700 cursor-pointer");
            this.pinButtonTarget.innerText = "재발송 하기"
          }
        }
      } else {
        alert(res.data.msg);
      }
    })
  }

  askEmailValidness(email) {
    const mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const errorClass = "bg-white dark:bg-stone-900 bg-dark-maily text-slate-900 dark:text-slate-200 shadow-sm focus:ring-yellow-300 focus:border-yellow-300 block w-full sm:text-sm border-yellow-300 rounded-md"
    const succeedClass = "bg-white dark:bg-stone-900 bg-dark-maily text-slate-900 dark:text-slate-200 shadow-sm focus:ring-green-333 focus:border-green-333 block w-full sm:text-sm border-green-333 rounded-md";

    if (email.match(mailformat)) {
      api.post("/api/users/check", { email: email }).then((res) => {
        if (res.status === 200) {
          if (res.data.exist) {
            this.emailFieldTarget.className = errorClass;
            this.notificationTarget.className = "mt-2 text-sm text-slate-700 dark:text-slate-300";
            this.notificationTarget.innerHTML = res.data.msg;
          } else {
            this.emailFieldTarget.className = succeedClass;
            this.pinContainerTarget.classList.add("hidden");
            this.notificationTarget.className = "mt-2 text-sm text-green-700 dark:text-green-700";
            this.notificationTarget.innerHTML = res.data.msg;
            this.userPinFieldTarget.required = false;
          }
        } else {
          alert(res.data.msg);
        }

        if (res.data.should_verify) {
          this.pinContainerTarget.classList.remove("hidden");
          this.userPinFieldTarget.required = true;
        }
      });

    } else {
      this.emailFieldTarget.className = errorClass;
      this.notificationTarget.className = "mt-2 text-sm text-red-700";
      this.notificationTarget.innerText = "이메일 형식을 확인해주세요";
    }
  }

  validateEmail(e) {
    if (this.emailValidnessTimeout != null) {
      clearTimeout(this.emailValidnessTimeout);
    }

    const email = e.target.value;

    this.emailValidnessTimeout = setTimeout(() => {
      this.emailValidnessTimeout = null;
      this.askEmailValidness(email);
    }, 300)
  }

  connect() {
    if (this.emailFieldTarget.value) {
      this.askEmailValidness(this.emailFieldTarget.value);
    }
    if (this.emailValidnessTimeout) {
      clearTimeout(this.emailValidnessTimeout);
    }
  }
}