// <div data-controller="writers--new">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

// https://stimulus.hotwired.dev/reference
export default class extends Controller {
  static targets = [ "spaceUrlField", "spaceUrlValidation" ];
  static classes = [ "" ]
  static values = {};

  initialize() {
    this.verifySpaceUrlTimeout = null;
  }

  verifySpaceUrl = (spaceUrl) => {
    api.post("/api/writers/verify_space_url", { space_url: spaceUrl }).then((res) => {
      if (res.status === 200) {
        if (res.data.verified) {
          this.spaceUrlValidationTarget.className = "mt-2 text-sm text-green-700";
          this.spaceUrlValidationTarget.innerText = res.data.msg;
        } else {
          this.spaceUrlValidationTarget.className = "mt-2 text-sm text-red-700";
          this.spaceUrlValidationTarget.innerText = res.data.msg;
        }
      } else {
        alert(res.data.msg);
      }
    })
  }

  validateSpaceUrl(e) {
    if (this.verifySpaceUrlTimeout != null) {
      clearTimeout(this.verifySpaceUrlTimeout);
    }

    const spaceUrl = e.target.value;

    this.verifySpaceUrlTimeout = setTimeout(() => {
      this.verifySpaceUrlTimeout = null;
      this.verifySpaceUrl(spaceUrl)
    }, 500)
  }

  connect() {

  }

  disconnect() {
    if (this.verifySpaceUrlTimeout) {
      clearTimeout(this.verifySpaceUrlTimeout);
    }
  }
}