// <div class="my-4">
//     <div class="sm:col-span-6" data-controller="components--file-upload">
//       <%= f.label :cover_image, class: "block text-base font-medium leading-7 text-slate-900 dark:text-slate-200" %>
//       <div class="mt-2 flex items-center justify-center h-32 lg:h-64 px-6 pt-5 pb-6 border-2 border-gray-300 dark:border-gray-500 border-dashed rounded-md bg-white dark:bg-stone-900 cursor-pointer blog-cover has-image for-upload"
//            style="background-image: url('<%= polymorphic_url(post.cover_image) if post.cover_image.attached? %>');"
//            data-action="click->components--file-upload#onClickFileField"
//            data-target="components--file-upload.fileContainer">
//         <%= f.file_field :cover_image, class: "hidden",
//                          accept: 'image/png,image/jpeg,image/jpg', data: {
//                 target: "components--file-upload.fileField",
//                 action: "change->components--file-upload#afterImageSubmit",
//             } %>
//         <div class="text-center pointer-events-none">
//           <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
//             <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
//           </svg>
//           <p class="mt-1 text-sm text-slate-600 dark:text-slate-300">
//             <button type="button" class="font-medium text-green-700 hover:text-green-600 focus:outline-none focus:underline transition duration-150 ease-in-out">
//               클릭해서 이미지를 올려보세요
//             </button>
//           </p>
//           <p class="mt-1 text-xs text-slate-600 dark:text-slate-300">
//             PNG, JPG, JPEG 파일로 2MB 까지 올릴 수 있습니다.
//           </p>
//         </div>
//       </div>
//     </div>
//   </div>
import { Controller } from "stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [
      "fileContainer", "fileField",
    "thumbnailImage", "removeFileField",
    "removeThumbnailImageContainer", "removeThumbnailImageCheckBox"
  ];

  onClickFileField() {
    this.fileFieldTarget.click();
  }

  afterImageSubmitThumbnail(event) {
    if (event.target.files[0]) {
      if (this.hasRemoveThumbnailImageCheckBox) {
        this.removeThumbnailImageCheckBoxTarget.checked = false;
      }

      const reader = new FileReader();
      reader.onload = () => {
        this.thumbnailImageTarget.src = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      if (this.hasRemoveThumbnailImageContainer) {
        this.removeThumbnailImageContainerTarget.classList.remove("hidden");
      }
    }
  }

  removeThumbnailImage(e) {
    if (e.target.checked) {
      this.fileFieldTarget.value = null;
      this.thumbnailImageTarget.src = "";
      this.removeThumbnailImageContainerTarget.classList.add("hidden");
    } else {
      this.thumbnailImageTarget.src = e.target.dataset.originalUrl;
    }
  }

  afterImageSubmit(event) {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        this.fileContainerTarget.style.backgroundImage = `url(${reader.result})`;
      };
      reader.readAsDataURL(event.target.files[0]);
    }

    if (this.hasRemoveFileFieldTarget) {
      this.removeFileFieldTarget.classList.remove("hidden")
    }
  }

  removeFile(e) {
    if (e.target.checked) {
      try{
        this.fileFieldTarget.value = '';
        if(this.fileFieldTarget.value){
          this.fileFieldTarget.type = "text";
          this.fileFieldTarget.type = "file";
        }
        this.fileContainerTarget.style.backgroundImage = `url(${e.target.dataset.placeholderUrl})`;
      } catch(exception){
        console.log(exception)
      }
    } else {
      this.fileContainerTarget.style.backgroundImage = `url(${e.target.dataset.originalUrl})`;
    }

    if (this.hasRemoveFileFieldTarget) {
      this.removeFileFieldTarget.classList.add("hidden")
    }
  }

  connect() {
  }
}