// <div data-controller="subscriptions--gift">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [ "planTypeInput", "totalPrice", "receiverEmail" ];

  get receiversCount() {
    let count = 0;

    this.receiverEmailTargets.forEach((target) => {
      if (target.value) {
        count += 1;
      }
    });
    return count;
  }

  calculatePrice() {
    this.planTypeInputTargets.forEach((target) => {
      if (target.value === "monthly" && target.checked) {
        const totalPrice = target.dataset.price * this.receiversCount;
        this.setPrice(totalPrice);
      } else if (target.value === "yearly" && target.checked) {
        const totalPrice = target.dataset.price * this.receiversCount;
        this.setPrice(totalPrice);
      }
    });
  }

  setPrice(price) {
    this.totalPriceTarget.innerText = `${price.toLocaleString()}원`;
  }

  connect() {
    this.calculatePrice();
  }
}