// <div data-controller="components--select">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [ "form", "remoteSubmit", "selectableContent" ];

  onSelectVersion(e) {
    this.formTarget.requestSubmit();
  }

  toggleSelectableContent(e) {
    if (e.target.checked) {
      this.selectableContentTarget.classList.remove("hidden")
    } else {
      this.selectableContentTarget.classList.add("hidden")
    }
  }

  connect() {
  }
}