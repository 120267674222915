import {Controller} from "stimulus";

export default class extends Controller {
  //
  connect() {
    console.log(this);
  }

  onPaste(event) {
    const paste = (event.clipboardData || window.clipboardData).getData('text/html');
    const pasteEl = document.createElement( 'div' );
    pasteEl.innerHTML = paste;
    const elements = pasteEl.childNodes;
    // console.log(paste);
    console.log(elements);

    // console.log(event.target);
    // console.log("onPaste");
    // event.stopImmediatePropagation();
  }

  // disconnect() {
  //
  // }
}

