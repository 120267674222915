// <div data-controller="components--editor-html">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

const linkCheck = str => /(https:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(str);
const hasHttp = str => /(http(s?)):\/\//i.test(str)
let headerIndex = 0;

const transforms = {
  delimiter: () => {
    return `<hr>`;
  },

  header: ({ id, data, tunes }) => {
    let alignment;
    if (data.alignment) {
      alignment = data.alignment;
    } else if (tunes) {
      const { alignmentTune } = tunes;
      alignment = alignmentTune.alignment;
    }

    headerIndex += 1;
    return `<h${data.level} id="${id}" style="text-align: ${alignment ? alignment : "left"}">${data.text}</h${data.level}>`;
  },

  paragraph: ({ data, tunes }) => {
    let alignment;
    if (data.alignment) {
      alignment = data.alignment;
    } else if (tunes) {
      const { alignmentTune } = tunes;
      alignment = alignmentTune.alignment;
    }

    return `<p style="text-align: ${alignment ? alignment : "left"}">${data.text || "&nbsp;"}</p>`;
  },

  linkTool: ({ data }) => {
    let hostName;
    const link = data.link;
    const { title, description, image } = data.meta;

    let parsedDescription;
    if (typeof(description) !== "string") {
      parsedDescription = "&nbsp;";
    } else {
      parsedDescription = description;
    }

    try {
      hostName = (new URL(data.link)).hostname;
    } catch (e) {
      hostName = link;
    }

    let image_url;
    try {
      image_url = image ? image.url : ""
    } catch (e) {
      image_url = "https://cdn.maily.so/images/green_logo.png"
    }

    let full_link = link;
    if(!hasHttp(link)) {
      full_link = `https://${full_link}`
    }

    return `<div class="link-tool"><a href="${full_link}" class="my-4 link-tool__content link-tool__content--rendered" rel="nofollow noindex noreferrer"><div class="link-tool__image" style="background-image: url(&quot;${image_url}&quot;);"></div><div class="link-tool__title">${title}</div><p class="link-tool__description">${parsedDescription}</p><span class="link-tool__anchor">${hostName}</span></a></div>`;
  },

  list: ({ data }) => {
    let style = data.style === "unordered" ? "ul" : "ol";
    let list = data.items
      .map((i) => `<li> ${i} </li>`)
      .reduce((a, c) => a + c, "");
    return `<${style}>${list}</${style}>`;
  },

  image: ({ data }) => {
    const { caption, file, stretched, withBackground, withBorder, link } = data;
    const imageWidth = (stretched ? "100%" : (withBackground ? "60%" : "100%"));
    let imageDiv;

    if (link) {
      const imageTag = `<img class="${withBorder ? "border" : ""}" loading="lazy" style="margin: 0 auto; width: ${withBackground ? "auto" : "100%"};" src="${file.url}" alt="${caption}">`;
      if ((link.trim() !== "") && linkCheck(link)) {
        let full_link = link;
        if(!hasHttp(link)) {
          full_link = `https://${full_link}`
        }
        imageDiv = `<a href="${full_link}" style="width: ${imageWidth};margin: 0 auto;display: block;">${imageTag}</a>`;
      } else {
        imageDiv = `<div style="width: ${imageWidth};margin: 0 auto;">${imageTag}</div>`;
      }
    } else {
      const imageTag = `<img data-action="zoom" loading="lazy" class="${withBorder ? "border" : ""}" style="width: ${withBackground ? "auto" : "100%"};margin: 0 auto;" src="${file.url}" alt="${caption}">`;
      imageDiv = `<div style="width: ${imageWidth};margin: 0 auto;">${imageTag}</div>`;
    }

    return `<figure>${imageDiv}${ caption && `<figcaption class="text-center">${caption}</figcaption>` }</figure>`;
  },

  quote: ({ data }) => {
    const { text, caption, alignment } = data;
    return `<figure><blockquote><p class="text-${alignment}">${text}</p><figcaption class="text-right">${caption}</figcaption></blockquote></figure>`;
  },

  code: ({ data }) => {
    const { code } = data;

    return `<div style="padding: 16px;
    display: flex;
    width: 100%;
    margin: .4em 0;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background: rgba(235, 236, 237, 0.3);"><div style="
    width: 100%;
    white-space: pre-wrap;
    word-break: break-word;
    caret-color: rgb(55, 53, 47);">${code}</div></div>`
  },

  table: ({ data }) => {
    const { content } = data;
    const heading = content.shift();
    let thead = "";
    let tbody = "";

    if (heading && heading.length > 0) {
      const headRows = heading
        .map((e) => `<th>${e}</th>`).reduce((a, c) => a + c, "");

      thead = `<thead><tr>${headRows}</tr></thead>`;
    }

    if (content && content.length > 0) {
      const bodyRows = content.map((row) => {
        return `<tr>${row.map((e) => `<td>${e}</td>`).reduce((a, c) => a + c, "")}</tr>`
      }).reduce((a, c) => a + c, "");

      tbody = `<tbody>${bodyRows}</tbody>`;
    }

    return `<table>${thead}${tbody}</table>`
  },

  raw: ({ data }) => {
    return `<div class="w-full overflow-auto hide-scrollbar">${data.html}</div>`;
  },

  embed: ({ data }) => {
    const { service, caption, embed, height, width, source } = data;

    const iframe = `<iframe style="max-width: 100%; margin: 0 auto;" width="${width}" height="${height}" src="${embed}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`

    return `<figure>${iframe}${ caption && `<figcaption class="text-center">${caption}</figcaption>` }</figure>`
  },

  AnyButton: ({ data }) => {
    const { link, text, alignment } = data;
    let full_link = link;
    if(!hasHttp(link)) {
      full_link = `https://${full_link}`
    }
    return `<p style="text-align: ${alignment}"><a href="${full_link}" class="maily-green-button editor-button">${text}</a></p>`
  }
};

export default class extends Controller {
  static targets = [ "article" ];

  parseFunctionError(type){
    return new Error(`\x1b[31m The Parser function of type "${type}" is not defined. \n
  Define your custom parser functions as: \x1b[34m \x1b[0m`);
  }

  parseEditorData = ({ blocks }) => {
    if (blocks) {
      return blocks.map((block) => {
        return transforms[block.type]?
          transforms[block.type](block)
            .replace(/%name%/g, this.data.get("user-name"))
              .replace(/<a href/g, "<a target='_blank' href")
          : this.parseFunctionError(block.type);
      }).join('');
    }
  };

  connect() {
    let editorData = JSON.parse(this.data.get("editor-data"));
    if(isEmpty(editorData)) {
      editorData = {};
    }

    const parsedEditorData = this.parseEditorData(editorData);
    if (parsedEditorData) {
      this.articleTarget.innerHTML = parsedEditorData;
    }
  }
}

function isEmpty(obj) {
  for(let key in obj) {
    if(obj.hasOwnProperty(key))
      return false;
  }
  return true;
}