import {Controller} from "stimulus";

export default class extends Controller {
  dataReceive(e) {
    if(e.type === "editor-title-change") {
      this.context.element.children[0].innerText = e.detail
    } else {
      this.context.element.children[1].innerText = e.detail
    }
  }
}