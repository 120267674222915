// <div data-controller="components--top-banner">
//
// </div>
import { Controller } from "stimulus";
import api from "@/utils/api";
import helpers from "@/utils/helpers";

export default class extends Controller {
  static targets = [ "" ];
  static values = {
    dismissKey: String
  };

  dismiss() {
    api.post("/api/faces/dismiss", {
      dismiss_key: this.dismissKeyValue
    })
  }

  dismissNotice() {
    api.post("/api/notices/dismiss")
  }

  connect() {
  }
}